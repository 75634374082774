import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  StyledModal,
  StyledTitle,
  StyledBody,
  DetailInitiativeWrapper,
} from "../../EngagementSectionV2/CompanyInitiative/styles";
import { ImageUrl } from "../../../utils/constants";
import { ProgressBar } from "../../HealthTabV2/HealthTabUpdated/styles";
import {
  closeIcon
} from "../../../utils/icons";

class InfoDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initiative: null,
      printCSV:false,
      isloading:false,
      initiativeReportData:[]
    };
  }

  showDetailInitiative = () => {
    const { infoDetailsData } = this.props;
    return (
      <React.Fragment>
        <div className="row-one">
          <div style={{ display: "flex" }}>
            <div className="image">
              <img
                src={ImageUrl + "/" + infoDetailsData?.white_icon}
              />
            </div>
            <div className="details">
              <div>
                <span className="name">{infoDetailsData?.core_value}</span>
              </div>
              <div style={{ marginTop: "10px" }}>
                <span className="points">
                  {infoDetailsData?.active_status ?"Active":"Upcoming"} 
                  {infoDetailsData?.point&&(` | +${infoDetailsData?.point} pts`)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row-two">
          <div style={{display:"flex", alignItems:"center"}}>
            <span className="user-count" style={{color:infoDetailsData?.color, fontSize: '24px', lineHeight: '24px', fontFamily: 'Rubik-Medium'}}>{infoDetailsData?.total_user_received_count} Users Received</span>
          </div>
          <div>
            <span className="percent" style={{color:infoDetailsData?.color, fontSize: '24px', lineHeight: '24px', fontFamily: 'Rubik-Medium'}}>{infoDetailsData?.user_received_percentage}%</span>
          </div>
        </div>
        <div
          className="row-three"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ProgressBar
              background={"#005C870D"}
              backgroundActive={infoDetailsData?.color?infoDetailsData?.color:"#005c87"}
              width={infoDetailsData?.user_received_percentage > 100 ? 100 : infoDetailsData?.user_received_percentage}
              height={"20px"}
              paddingRight="0px"
            >
              <div className="progress" style={{boxShadow:"unset"}}></div>
            </ProgressBar>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { showModal, onClose, infoDetailsData } = this.props;
    return (
      <StyledModal
        transform={1}
        show={showModal}
        newWidth={"600px"}
        newBg={"newBg"}
        height="50px"
        display="none"
      >
        <Modal.Header
          closeButton
          onHide={() => onClose()}
          style={{ background: "white" }}
        >
          <StyledTitle style={{ color: "#005C87", width:"100%", fontSize: "18px", justifyContent:"flex-start", marginLeft: '35%', lineHeight:"24px" }}>
            Core Value Info
            <div  onClick={() => onClose()} style={{display:"flex", margin:"auto 0 auto auto", cursor:"pointer"}}>
              {closeIcon()}
            </div>
          </StyledTitle>
        </Modal.Header>
        <StyledBody style={{padding: "24px 24px 16px 24px"}}>
          <DetailInitiativeWrapper background={infoDetailsData?.color?infoDetailsData?.color:"#005c87"}>
            {this.showDetailInitiative()}
          </DetailInitiativeWrapper>
        </StyledBody>
      </StyledModal>
    );
  }
}

InfoDetails.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  history: PropTypes.object,
  infoDetailsData: PropTypes.array
};

export default (InfoDetails);
