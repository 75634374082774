import styled from 'styled-components';
import {Modal} from "react-bootstrap";

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 500px;
    ${'' /* height: 450px; */}
    border-radius: 6px;
    background: linear-gradient(180deg, #FFFFFF 0%,rgba(255,255,255,0) 100%), #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    transform: translate(0, 50vh) translate(0, -50%) !important;
    margin: 0 auto;
    .modal-content {
      background-color: ${({bgcolor}) => (bgcolor || !bgcolor) ? 'transparent' : '#fff'};
    }
    .close {
      margin-top: -30px;
    }
    ${'' /* @media (max-height: 1200px) {
      width: 55%;
    } */}
    @media (max-height: 530px) {
      transform: none !important;
      margin: 30px auto;
    }
    @media (max-width: 766px) {
      width: 95%;
    }
  }
`;

const StyledHeader = styled(Modal.Header)`
  display: flex;
  padding: 15px 0;
  border-bottom: none;
  background: linear-gradient(180deg, #52A8ED 0%, #8AD3F7 100%);
  border-radius: 6px 6px 0px 0px;
  text-align: center;
  > span {
    font-size: 18px;
    font-family: 'Rubik-Medium';
    color: #0D4270;
    text-decoration: underline;
    text-transform: uppercase;
  }
`;
const StyledBody = styled(Modal.Body)`
height: 100%;
width: 100%;
padding:30px;
`;

const MainContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  >div{
    margin: 5px;
  }
`

const PeerButton = styled.div`
    width: 45%;
    height: 49px;
    padding: 5px 25px; 
    cursor: pointer;
    margin-bottom: 10px;
    background: #F9F9F9;
    border-radius: 6px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    >img{
      width: 25px;
      height: 25px;
    }
    >div{
        width: 90%;
        text-align: left;
        display: flex;
        justify-content: left;
        padding-left: 15px;
        align-items: center;
        font-size: 16px;
    }
`;

export { StyledModal, StyledHeader, StyledBody, MainContent, PeerButton}
