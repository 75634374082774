/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { HeadingContainer, MainContainer, CoreValueWrapper, CoreValueCard, HoverBackground } from './styles';
import { getCompanyExemplifies, addCompanyExemplifiesValues, updateCompanyExemplifyValues, getUserRole } from "../../../redux/actions";
import { ImageUrl } from '../../../utils/constants';
import { isNull, isUndefined, isEmpty } from 'lodash';
import Waiting from '../../Waiting';
import { ToolTip } from '../../AdminTeamCreation/styles';
import HoverButton from '../../common/HoverButton';
import { withTranslation } from 'react-i18next';
import { ViewProfileIcon, LaunchEventIcon, EditBackIcon} from '../../../utils/icons';
import InfoDetails from './infoPopup';
import {HeaderContainer} from '../../HealthTab/styles';
import {HeaderTitle} from '../styles';
import {BuddyTab} from '../../PeopleHomeV2/styles';

class AddCoreValues2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      editObj: {},
      editIndex: 0,
      saveDetailsContainer: true,
      isSaveButtonEnabled: false,
      array: [{ name: "an" }, { name: "an" }, { name: "an" }],
      showEditPopup: false,
      selectedPoint: { value: 5, text: "5 Pts" },
      pointsArrow : false,
      pointsArray : [
        { value: 5, text: "5 Pts" },
        { value: 10, text: "10 Pts" },
        { value: 15, text: "15 Pts" },
        { value: 20, text: "20 Pts" },
        { value: 25, text: "25 Pts" },
        { value: 30, text: "30 Pts" },
        { value: 35, text: "35 Pts" },
        { value: 40, text: "40 Pts" },
        { value: 45, text: "45 Pts" },
        { value: 50, text: "50 Pts" }
      ],
      recognitionArray: [
        {
          recognitionName: '',
          iconId: '',
          iconPath: '',
          recognitionId: '',
          active_status: 0,
          description: "",
          point:0
        }
      ],
      isDeleted: [],
      active: true,
      topRecognitionArray: [
        {
          recognitionName: '',
          iconId: '',
          iconPath: '',
          recognitionId: '',
          active_status: 0,
          description: "",
          point:0
        }
      ],
      onHover:-1,
      showModal: false,
      infoDetailsData: null,
      label: "Library"
    }
  }

  changeModal = () => {
    this.setState({ showEditPopup: !this.state.showEditPopup, /* active: false, */ selectedPoint : {value: 5, text: "5 Pts"} });
  };

  componentDidMount() {
    const { getCompanyExemplifies, companies, companyInfo, fetchUserRole } = this.props;
    getCompanyExemplifies(companyInfo ? companyInfo.id : companies.id);
    fetchUserRole();
  }

  componentDidUpdate(prevProps) {
    const { companyExemplifiesValues } = this.props;
    const { recognitionArray } = this.state;
    if (companyExemplifiesValues !== prevProps.companyExemplifiesValues) {
      let recognitions = [];
      let topRecognition = [];
      if ( companyExemplifiesValues && companyExemplifiesValues.length > 0) {
        companyExemplifiesValues.forEach((data) => {
          let object = {
            recognitionName: data.core_value,
            iconPath: data.image,
            recognitionId: data.id,
            active_status: data.active_status,
            description: data.description ? data.description : 'Describe the company core value or recognition here',
            point:data.point
          }
          if (data.is_default) { topRecognition.push(object) }
          else {
            recognitions.push(object)
          }
        });
      }
      this.setState({ recognitionArray: recognitions, topRecognitionArray: topRecognition })
    }
    if (recognitionArray.length === 0) {
      this.addRecognition();
    }
  }

  saveRecognition = () => {
    const { editObj } = this.state;
    const { companyInfo, companies, addCompanyExemplifiesValues, history } = this.props;
    let recognitions = [];
    let companyId = companyInfo ? companyInfo.id : companies.id;
    let object = {
      core_value: editObj.recognitionName,
      image: editObj.iconPath,
      id: editObj.recognitionId,
      description: editObj.description,
      status: editObj.active_status,
      point: editObj.point
    }
    recognitions.push(object);
    let saveObject = {
      company_id: companyId,
      recognitions: recognitions,
      is_deleted: []
    }
    addCompanyExemplifiesValues(saveObject, companyId, history);
  }

  saveRecognitionAll = () => {
    const { companyInfo, companies, updateCompanyExemplifyValues } = this.props;
    let is_Deleted = [];
    let final_recognition = [];
    let companyId = companyInfo ? companyInfo.id : companies.id;
    const deleted = this.state.recognitionArray.filter((obj) => (obj.active_status === false))
    deleted.forEach((data) => {
      is_Deleted.push(data.recognitionId);
    })

    const recognition = this.state.recognitionArray.filter((obj) => (obj.active_status === true))
    recognition.forEach((data) => {
      let object = {
        id: data.recognitionId,
        status: 1
      }
      final_recognition.push(object);
    })
    let saveObject = {
      company_id: companyId,
      recognitions: final_recognition,
      is_deleted: is_Deleted

    }
    updateCompanyExemplifyValues(saveObject);
  }

  userEdit = (obj, index) => {
    this.setState({ active: obj.recognitionName ? true : false , editObj: obj, editIndex: index, showEditPopup: !this.state.showEditPopup },()=> {
      const { editObj, pointsArray } = this.state;
      if (editObj && editObj.point) {
        const selectedPointNew = pointsArray.find((point) => point.value === editObj.point);
        if (selectedPointNew) {
          this.setState({ selectedPoint: selectedPointNew });
        }
      }
    })
  }

  tabs = [
    {
      label:"Analysis"
    },
    {
      label:"Activity"
    },
    {
      label:"Best Performer"
    },
    {
      label:"Anniversaries"
    },
    {
      label:"Library"
    }
  ]

  Header = () => {
    const {label} = this.state;
    return(
      <HeaderContainer padding="1" style={{borderRadius:'6px', padding:'8px', marginBottom:"20px"}}>
        <HeaderTitle>
          {this.tabs.map((tab,index)=>(
            <BuddyTab
              key={index}
              width="1"
              padding="1"
              margin={"0px 15px 0px 15px"}
              active={label == tab.label ? true : false}
              style={{ background: label == tab.label ? 'rgba(0, 122, 177, 1)' : "unset", color: label == tab.label ? "#fff" : "rgba(0, 122, 177, 1)" }}
              onClick={()=>this.props.history.push({pathname:`/company/recognition`, state:{label:tab.label}})}
            >
              {tab?.label}
            </BuddyTab>))}
        </HeaderTitle>
      </HeaderContainer>)
  }

  close = () => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_14978_75731"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <rect width={24} height={24} fill="#C4C4C4" fillOpacity={1}/>
      </mask>
      <g mask="url(#mask0_14978_75731)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3605 0.678084C22.6358 -0.0466026 21.4608 -0.0466023 20.7361 0.678084L11.9067 9.50756L3.07795 0.678849C2.4145 0.0154025 1.33884 0.0154032 0.675399 0.678849C0.0119522 1.3423 0.0119521 2.41796 0.675399 3.0814L9.50411 11.9101L0.675642 20.7386C-0.0490441 21.4633 -0.0490441 22.6382 0.675643 23.3629C1.40033 24.0876 2.57528 24.0876 3.29996 23.3629L12.1284 14.5344L20.9577 23.3637C21.6211 24.0271 22.6968 24.0271 23.3602 23.3637C24.0237 22.7002 24.0237 21.6246 23.3602 20.9611L14.531 12.1319L23.3605 3.30241C24.0851 2.57772 24.0851 1.40277 23.3605 0.678084Z"
          fill="#6099b4"
        />
      </g>
    </svg>
  );

  HeaderV2 = () => (
    <HeadingContainer>
      {/* <img style={{marginLeft: '10px', cursor: 'pointer'}} onClick={() => {this.setState({ saveDetailsContainer: true, hidden: false })}}src='/public/images/backcorevalueButton.png'></img> */}
      <ToolTip
        margin={"auto 10px auto 0"}
        activeValue={"transparent"}
        height="40px"
        width="55px"
      >
        <HoverButton
          title={this.props.t("Close")}
          width="24px"
          height="24px"
          svgPath={this.close()}
          fillOpacity={1} backgroundColor={"#f0f6f8"} backgroundColorActive={"#005c87"} activeColor={"#598ea8"} inActiveColor={"white"}
          onClick={() => {this.setState({ saveDetailsContainer: true, hidden: false })}}
        />
      </ToolTip>
      <h1 style={{color: '#005c87',marginLeft:"0px"}}>{this.props.t("Add Company Core Value")}</h1>
    </HeadingContainer>
  )

  launchInitiatives = (index, obj) => {
    const temp = this.state.recognitionArray;
    temp[index].active_status = !obj.active_status;
    this.setState({
      recognitionArray: temp
    }, () => this.saveRecognitionAll());
  }

  onSelect = (selectedPoint) => {
    this.setState({
      active: true, editObj: { ...this.state.editObj, point: selectedPoint.value },
      selectedPoint: selectedPoint,
      pointsArrow: false // Close dropdown after selection
    });
  };

  onChangeHandlerRecognitionName = (e) => {
    this.setState({ 
      active: e.target.value ? true : false, 
      editObj: { ...this.state.editObj, recognitionName: e.target.value } 
    });
  }

  onHoverMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveHoverMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  showViewDetails = (data) => {
    this.setState({
      infoDetailsData: data,
      showModal: true
    })
  }

  hideViewDetails = () => {
    this.setState({
      infoDetailsData: null,
      showModal: false
    })
  }

  showCoreValuesAll = () =>{
    const {companyExemplifiesValues} = this.props;
    const {onHover} = this.state;
    return(
      <CoreValueWrapper>
        <div className='heading'>
          Company Core Value
        </div>
        <div className='wrapper'>
          {companyExemplifiesValues?.length>0 && companyExemplifiesValues?.map((data,index)=>(
            <>
              <CoreValueCard key={index} background={data?.color} onMouseEnter={() => this.onHoverMouse(data?.id)} onMouseLeave={() => this.onLeaveHoverMouse()}>
                <div className='image'>
                  <img src={ImageUrl + "/" + data?.white_icon} />
                </div>
                <div className='text'>{data?.core_value}
                </div>
                {(onHover==data.id)&&(!data.active_status?<HoverBackground onClick={()=>this.props.history.push({pathname:`/company/recognition/addcorevalues/launch`, state:{title:data.core_value, image:data?.white_icon, description:data?.description, points:data?.point, color:data?.color, status:true, id: data?.id, type: "launch"}})}>
                  <div className='view-button'>
                    <div style={{ marginRight: '10px' }}><LaunchEventIcon/></div>
                    Launch</div>
                </HoverBackground>:
                  <HoverBackground>
                    <div className='view-button' onClick={()=>this.showViewDetails(data)}>
                      <div style={{ marginRight: '10px' }}><ViewProfileIcon/></div>
                      View</div>
                    <div className='edit-photo'
                      onClick={()=>this.props.history.push({pathname:`/company/recognition/addcorevalues/edit`, state:{title:data.core_value, image:data?.white_icon, description:data?.description, points:data?.point, color:data?.color, status:data?.active_status, id: data?.id}})}
                    ><EditBackIcon/></div>
                  </HoverBackground>)}
              </CoreValueCard></>))}
        </div>
      </CoreValueWrapper>
    )
  }

  render() {
    const {companyExemplifiesValues} = this.props;
    const {infoDetailsData, showModal} = this.state;
    if(isUndefined(companyExemplifiesValues) || isNull(companyExemplifiesValues) || isEmpty(companyExemplifiesValues)){
      return <Waiting/>
    }
    return (
      <MainContainer>
        <div className='main'>
          {this.Header()}
          {/* {this.CoreRecognition()} */}
          {this.showCoreValuesAll()}
          {/* {this.state.saveDetailsContainer && this.BasicRecognition()}
          {this.EditPopUp()} */}
          {<InfoDetails showModal={showModal} infoDetailsData={infoDetailsData} onClose={this.hideViewDetails}/>}
        </div>
      </MainContainer>
    );
  }
}

AddCoreValues2.propTypes = {
  companies: PropTypes.array,
  companyInfo: PropTypes.object.isRequired,
  history: PropTypes.object,
  getCompanyExemplifies: PropTypes.func,
  companyExemplifiesValues: PropTypes.array,
  recognitionIcons: PropTypes.array,
  addCompanyExemplifiesValues: PropTypes.func,
  updateCompanyExemplifyValues: PropTypes.func,
  role: PropTypes.string.isRequired,
  fetchUserRole: PropTypes.func,
  t: PropTypes.func
}

const mapStateToProps = (state) => ({
  companyExemplifiesValues: state.social.companyExemplifiesValues,
  role: state.auth.role,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyExemplifies: (id) => dispatch(getCompanyExemplifies(id)),
  addCompanyExemplifiesValues: (data, companyId, history) => dispatch(addCompanyExemplifiesValues(data, companyId, history)),
  updateCompanyExemplifyValues: (data) => dispatch(updateCompanyExemplifyValues(data)),
  fetchUserRole: () => dispatch(getUserRole()),
})

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(AddCoreValues2));